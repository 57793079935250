import styled from 'styled-components';

import { hexToRGBA } from '@/styles/colors';

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
`;

export const TransactionWrapper = styled.div`
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  height: 18%;
  justify-content: space-evenly;
  max-height: 68px;
  max-width: 768px;
  min-height: 40px;
  padding: 3px 0;
  width: 85%;

  &:hover,
  &:active {
    background: radial-gradient(
      circle,
      ${({ theme }) => hexToRGBA(theme.colors.info.gray, 0.05)} 0%,
      ${({ theme }) => hexToRGBA(theme.colors.info.gray, 0.5)} 0%,
      ${({ theme }) => hexToRGBA(theme.colors.info.gray, 0.05)} 100%
    );
  }

  &:not(:last-child) {
    border-bottom: ${({ theme }) =>
      `1px solid ${hexToRGBA(theme.colors.info.primary, 0.2)}`};
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 100%;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    max-width: 456px;
  }
`;
