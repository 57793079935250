import React, { useState } from 'react';
import PropTypes from 'prop-types';

import 'swiper/css';
import 'swiper/css/pagination';
import MerchanDisc from '@/components/atoms/MerchanDisco';
import './styles.css';
import { SwiperCarousel, SwiperSlideStyled, TitleStyled } from './styles';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import { STATUS_AFFILIATE } from '@/helpers/constants';
import IconVplus from '@/components/molecules/IconVplus';

const SLIDES_PER_VIEW = 3;
const SPACE_BETWEEN = 30;
const ONE = 1;
const TWO = 2;
const ZERO = 0;

function CarouselMech({ data = [], setSelectedMerchant, handleClickItem }) {
  const [currentIndexItem, setCurrentIndexItem] = useState(0);
  const quantityItens = data.length;
  const { isMobileLayout } = useDeviceWidth();
  const hasOnlyTwoItems = quantityItens === TWO && !isMobileLayout;
  return (
    <>
      <SwiperCarousel
        navigation={quantityItens !== ONE}
        centeredSlides
        slidesPerView={
          isMobileLayout
            ? ONE
            : quantityItens < SLIDES_PER_VIEW
            ? quantityItens
            : SLIDES_PER_VIEW
        }
        spaceBetween={SPACE_BETWEEN}
        pagination
        onRealIndexChange={(e) => {
          setCurrentIndexItem(e.realIndex);
          setSelectedMerchant(data[e.realIndex]);
        }}
        isMobileLayout={isMobileLayout}
        onlyTwoItems={hasOnlyTwoItems}
      >
        {data.map(
          ({ id, image_url, name, user_vplus_image_url, usernames }, index) => {
            const isSelected = index === currentIndexItem;
            const isAffiliate =
              STATUS_AFFILIATE === usernames[ZERO]?.affiliate_status;
            const isBlocked =
              usernames[ZERO]?.cashback_blocked === undefined
                ? false
                : usernames[ZERO]?.cashback_blocked;
            return (
              <SwiperSlideStyled isSelected={isSelected} key={id}>
                <>
                  <MerchanDisc
                    isSelected={isSelected}
                    src={
                      isAffiliate
                        ? user_vplus_image_url || image_url
                        : image_url
                    }
                    onClick={() => (isSelected ? handleClickItem() : null)}
                    isBlocked={isBlocked}
                  />
                  {isSelected && (
                    <div
                      isBlocked={isBlocked}
                      className={`flex gap-[0.625rem] items-start ${
                        isBlocked ? 'opacity-50' : 'opacity-100'
                      }`}
                    >
                      <TitleStyled color="light">{name}</TitleStyled>
                      {isAffiliate && <IconVplus />}
                    </div>
                  )}
                </>
              </SwiperSlideStyled>
            );
          },
        )}
      </SwiperCarousel>
    </>
  );
}
CarouselMech.propTypes = {
  data: PropTypes.array,
  setSelectedMerchant: PropTypes.func,
  handleClickItem: PropTypes.func,
}.isRequired;

export default CarouselMech;
