import React, { useEffect } from 'react';

import Carousel from '@/components/organisms/Carousel';
import GetStart from '@/components/organisms/GetStart';
import MainTemplate from '@/components/templates/MainTemplate';
import { useUserData } from '@/context/userContext';
import useUserStatus from '@/hooks/useUserStatus';

import { carouselData } from './data';
import { Container, ContainerSlider, Section, SectionDivider } from './styles';
import useAllMerchants from '@/hooks/useAllMerchants';
import CafValidation from '@/components/organisms/CafValidation';

function WalletHome() {
  const { getUserStatusAndRedirect } = useUserStatus();
  const { getAllMerchants } = useAllMerchants();

  const {
    userData: { status },
  } = useUserData();

  useEffect(() => {
    getUserStatusAndRedirect(status);
  }, [getUserStatusAndRedirect, status]);

  useEffect(() => {
    const controller = new AbortController();
    const merchantList =
      localStorage.getItem('merchants') !== 'undefined'
        ? JSON.parse(localStorage.getItem('merchants'))
        : null;

    if (!merchantList) {
      getAllMerchants();
    }

    return () => controller.abort();
  }, []);

  return (
    <MainTemplate
      containerProps={{
        gradient: true,
        background: status === 2 ? 'black' : '',
      }}
      headerProps={{
        mobileFullVersion: true,
        background: status === 2 ? 'black' : '',
      }}
      wrapperProps={{ background: status === 2 ? 'black' : '' }}
    >
      {status === 2 ? (
        <CafValidation />
      ) : (
        <Container>
          <GetStart />
          <SectionDivider />
          <Section hideInMobile>
            <ContainerSlider>
              <Carousel data={carouselData} />
            </ContainerSlider>
          </Section>
        </Container>
      )}
    </MainTemplate>
  );
}

export default WalletHome;
