import React from 'react';

import TransactionList from '@/components/molecules/TransactionList';
import i18n from '@/translate/i18n';
import useLastTransactions from '@/hooks/useLastTransactions';
import { useWalletData } from '@/context/walletContext';
import { LinkTextButton } from '@/components/atoms/Buttons/TextButton/styles';

import { Wrapper } from './styles';
import TwTitle from '@/components/atoms/TwTitle';
import useDeviceWidth from '@/hooks/useDeviceWidth';

function LastTransactionsHome() {
  const {
    walletData: { minimalTransactions },
  } = useWalletData();
  const { isLoading, lastTransactions, error } = useLastTransactions();
  const { isMobileLayout } = useDeviceWidth();

  const transactionList = minimalTransactions?.length
    ? minimalTransactions
    : lastTransactions;

  return (
    <Wrapper>
      <TwTitle
        text={i18n.t('getStart.lastTransactions')}
        size={isMobileLayout ? 'm_lg' : 'd_lg'}
        font="arboriaBold"
        classList="text-center mb-10"
      />
      <TransactionList
        listItems={transactionList}
        isLoading={!minimalTransactions?.length && isLoading}
        isError={error}
        type="transaction"
        errorText="error.lastTransactions.transactionsDefault"
      />
      {Boolean(transactionList?.length) && (
        <div>
          <LinkTextButton className="!text-lg" to="/transactions">
            {i18n.t('lastTransactions.showAll')}
          </LinkTextButton>
        </div>
      )}
    </Wrapper>
  );
}

export default LastTransactionsHome;
