import React from 'react';
import TwTitle from '@/components/atoms/TwTitle';
import i18n from '@/translate/i18n';

const SlideContentTwoApresentation = () => {
  const styleCommun = 'text-[50px] !leading-[58.24px] font-normal';

  return (
    <div className="flex flex-col size-full">
      <div className="flex flex-col gap-1 ml-9 mt-[115px] max-h-568:mt-6 max-h-810:mt-3">
        <TwTitle
          text={i18n.t('apresentationScreen.arrived')}
          backgroundText
          font="arboriaRegular"
          classList={styleCommun}
        />
        <TwTitle
          text={i18n.t('apresentationScreen.yourHour')}
          backgroundText
          font="arboriaRegular"
          classList={styleCommun}
        />
        <TwTitle
          text={i18n.t('apresentationScreen.ofWinning')}
          backgroundText
          font="arboriaRegular"
          classList={styleCommun}
        />
      </div>
    </div>
  );
};

export default SlideContentTwoApresentation;
