import React from 'react';
import PropTypes from 'prop-types';

import i18n from '@/translate/i18n';
import LogoVpag from '@/assets/vpagLogoH.svg';

import { Nav, NavItem, NavItemFilled, ProgressBar } from './styles';

function LoginRegisterSelector({
  isExternalLogin,
  origin = 'signIn',
  isEmailValid = false,
  isCreateAccount = false,
  stateProgressBar,
}) {
  const PERCENTAGE = {
    signUp: 35,
    validateEmail: 70,
    validateCpf: 100,
  };

  const PROMOCODE = localStorage.getItem('promocode');

  const PERCENTAGElOGIN = {
    signUp: 0,
    signUpNotEmpty: 25,
    createAccountButtonIsValid: 50,
    codeValidateFull: 75,
    validateCpf: 100,
  };

  const NAV_ITEM = [
    {
      id: 'sign-in-selector',
      type: 'signIn',
      label: i18n.t('loginRegisterSelector.login'),
      redirectTo: isExternalLogin
        ? `/transactions/signin/${sessionStorage.getItem(
            'pendingApiTransaction',
          )}`
        : '/signin',
    },
    {
      id: 'sign-up-selector',
      type: 'signUp',
      label: i18n.t('loginRegisterSelector.register'),
      redirectTo: isExternalLogin
        ? PROMOCODE
          ? `/external/signup?promocode=${PROMOCODE}`
          : '/external/signup'
        : PROMOCODE
        ? `/signup?promocode=${PROMOCODE}`
        : '/signup',
    },
  ];

  const NavComponent = isExternalLogin ? NavItemFilled : NavItem;

  return (
    <div
      className={`flex flex-col items-center justify-between w-full bg-black ${
        !isExternalLogin ? 'min-h-[175px]' : null
      }`}
    >
      {!isExternalLogin && (
        <div className="flex items-center justify-center bg-grass-800 h-[82px] w-full ">
          <img
            src={LogoVpag}
            alt="Vpag logo"
            className="fill-black h-[33px] w-[125px]"
          />
        </div>
      )}
      <section className="w-full max-w-[600px] px-10 md:px-16 flex flex-col items-center justify-between h-[61px]">
        {!isEmailValid && ['signIn', 'signUp'].includes(origin) && (
          <Nav>
            {NAV_ITEM?.map(({ id, label, redirectTo, type }, idx) => (
              <NavComponent
                key={id}
                id={id}
                active={type === origin}
                to={redirectTo}
                index={idx}
              >
                {label}
              </NavComponent>
            ))}
          </Nav>
        )}
        {origin !== 'signIn' &&
          (isCreateAccount ? (
            <div className="w-full">
              <ProgressBar progress={PERCENTAGElOGIN[stateProgressBar]} />
            </div>
          ) : (
            <div className="w-full">
              <ProgressBar progress={PERCENTAGE[origin]} />
            </div>
          ))}
      </section>
    </div>
  );
}

LoginRegisterSelector.propTypes = {
  isExternalLogin: PropTypes.bool,
  origin: PropTypes.string,
  isEmailValid: PropTypes.bool,
  isCreateAccount: PropTypes.bool,
  stateProgressBar: PropTypes.string,
};

export default LoginRegisterSelector;
