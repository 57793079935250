import React from 'react';
import { useHistory } from 'react-router-dom';

import Panel from '@/components/atoms/Panel';
import TwButton from '@/components/atoms/Buttons/TwButton';
import TwTextButton from '@/components/atoms/Buttons/TwTextButton';
import i18n from '@/translate/i18n';

const PanelButtonContainer = () => {
  const history = useHistory();

  return (
    <Panel classList="z-50 h-auto flex flex-col relative bg-gradient-to-t from-black to-transparent">
      <TwButton
        label={i18n.t('signin.register')}
        classList="!w-[320px] !h-[62px] rounded-[8px] mx-auto font-normal leading-[24px]"
        onClick={() => history.push('/signup', { fromButton: true })}
      />

      <div className="bg-gradient-to-t from-black to-transparent pt-[17px]">
        <TwTextButton
          label={i18n.t('signin.buttonAfter')}
          classList="!text-[16px] font-normal leading-[20px]"
          onClick={() => history.push('/', { fromButton: true })}
        />
      </div>
      <div className=" bg-black pb-[75px] max-h-810:p-2.5 max-h-810:pt-0" />
    </Panel>
  );
};

export default PanelButtonContainer;
