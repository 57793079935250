import React from 'react';
import PropTypes from 'prop-types';

import i18n from '@/translate/i18n';

import { ContentWrapper, Wrapper } from './styles';
import { createRandomId } from '@/helpers/functions';
import TwTitle from '@/components/atoms/TwTitle';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import TwDetailsLine from '@/components/v2/atoms/TwDetailsLine';

function TransactionDetailsSummary({
  data,
  bonus,
  hideTitle,
  customMargin,
  status = 'default',
}) {
  const { isMobileLayout } = useDeviceWidth();
  return (
    <Wrapper customMargin={customMargin}>
      {!hideTitle && (
        <TwTitle
          text={i18n.t('transactionDetailsSummary.title')}
          size={isMobileLayout ? 'm_md' : 'd_lg'}
          font="arboriaBold"
          classList="text-center"
        />
      )}
      <ContentWrapper>
        {Object.entries(data).map(([key, value]) => {
          if (!value) {
            return null;
          }

          return key.includes('youReceive') ||
            key.includes('youTransferred') ? (
            <TwDetailsLine
              info={`transactionDetailsSummary.${key}`}
              amount={value}
              size="sm"
              key={createRandomId()}
              divider
              colorStatus={status}
            />
          ) : key === 'bonus' ? (
            <TwDetailsLine
              bonus={bonus}
              amount={value}
              size="sm"
              key={createRandomId()}
            />
          ) : (
            <TwDetailsLine
              info={`transactionDetailsSummary.${key}`}
              amount={value}
              size="sm"
              key={createRandomId()}
            />
          );
        })}
      </ContentWrapper>
      <span />
    </Wrapper>
  );
}

TransactionDetailsSummary.propTypes = {
  bonus: PropTypes.shape,
  customMargin: PropTypes.string,
  data: PropTypes.shape,
  hideTitle: PropTypes.string,
  status: PropTypes.string,
};

export default TransactionDetailsSummary;
