import React from 'react';
import PropTypes from 'prop-types';

import { Container, Label, Option, SelectField } from './styles';

function Select({
  color,
  id,
  onChange,
  options,
  placeholder,
  value,
  variant,
  width,
  classList,
}) {
  return (
    <Container width={width} variant={variant}>
      <Label htmlFor={id} color={color} variant={variant}>
        {placeholder}
      </Label>
      <SelectField
        as="select"
        id={id}
        name={id}
        onChange={onChange}
        width={width}
        value={value}
        color={color}
        variant={variant}
        className={classList}
      >
        {options.map((opt) => (
          <Option key={opt.label} value={opt.value}>
            {opt.label}
          </Option>
        ))}
      </SelectField>
    </Container>
  );
}

Select.propTypes = {
  color: PropTypes.bool,
  id: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  variant: PropTypes.string,
  width: PropTypes.string,
}.isRequired;

export default Select;
