import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import ErrorMessage from '@/components/molecules/ErrorMessage';
import i18n from '@/translate/i18n';
import Loading from '@/components/atoms/Loading';
import TransactionResume from '@/components/molecules/TransactionResume';
import { Paragraph } from '@/components/atoms/Typography/styles';

import { TransactionWrapper, Wrapper } from './styles';

function TransactionList({ listItems, isLoading, isError, errorText }) {
  const history = useHistory();

  return (
    <Wrapper>
      {Boolean(listItems.length) &&
        listItems.map((el) => (
          <TransactionWrapper
            key={el.id}
            onClick={() => {
              history.push(`/transactions/${el.id}`);
            }}
          >
            <TransactionResume data={el} />
          </TransactionWrapper>
        ))}
      {isLoading && !listItems.length && <Loading marginTop="100px" />}
      {isError && !isLoading && <ErrorMessage content={i18n.t(errorText)} />}
      {!listItems.length && !isError && !isLoading && (
        <Paragraph textAlign="center">
          {i18n.t('lastTransactions.noData')}
        </Paragraph>
      )}
    </Wrapper>
  );
}

TransactionList.propTypes = {
  errorText: PropTypes.string,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
  listItems: PropTypes.shape,
}.isRequired;

export default TransactionList;
