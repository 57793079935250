import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { Form, Formik } from 'formik';
import { validateCPF } from 'validations-br';
import { Trans } from 'react-i18next';
import ReactFlagsSelect from 'react-flags-select';
import { AuthContext } from '@/context/authContext';
import { keysToRemoveOnLogout } from '@/helpers/constants';

import Loading from '@/components/atoms/Loading';
import { Paragraph } from '@/components/atoms/Typography/styles';
import Modal from '@/components/molecules/Modal';
import ConfirmAction from '@/components/molecules/Modal/ConfirmAction';
import { useUserData } from '@/context/userContext';
import { checkCpfRegex } from '@/helpers/functions';
import { formatCpf, cpfOnlyNumbers } from '@/helpers/stringFormat';
import { CPF_PHONE_VALIDATION_SCHEMA } from '@/helpers/schemas';
import { registrationCpf } from '@/services/api';
import i18n from '@/translate/i18n';

import { Container, LogoutButton } from './styles';
import TwInput from '@/components/atoms/Inputs/TwInput';
import TwSubmitButton from '@/components/atoms/Buttons/TwSubmitButton';
import TwTitle from '@/components/atoms/TwTitle';

const TIME_DELAY = 5000;

const WelcomeVpag = () => (
  <div className="flex flex-col gap-4">
    <TwTitle size="xl" color="grass-800" classList="text-center leading-5">
      {i18n.t('signup.welcomeVpag')}
    </TwTitle>
    <TwTitle size="xl" color="white" classList="text-center leading-5">
      {i18n.t('signup.yourWallet')}
    </TwTitle>
  </div>
);
function CpfValidationForm({ onComplete, checkValidCpf }) {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [showLogoutWarning, setShowLogoutWarning] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const { setUserData } = useUserData();
  const { setIsAuthenticated } = useContext(AuthContext);
  const [welcomeVpagShow, setWelcomeVpagShow] = useState(false);

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const validateUserData = async (values) => {
    if (validateCPF(values.cpf)) {
      setLoading(true);
      const payload = {
        cpf: cpfOnlyNumbers(values.cpf),
      };

      try {
        const res = await registrationCpf(payload);
        setWelcomeVpagShow(true);
        await delay(TIME_DELAY);
        setWelcomeVpagShow(false);
        setUserData((state) => ({ ...state, ...res.data }));
        onComplete();
      } catch (err) {
        const errorType = err?.response?.data?.message;
        if (errorType === 'cpf-validate-timeout') {
          onComplete();
        }

        if (errorType === 'invalid-cpf-numbers') {
          setValidationError(true);
        }

        if (errorType === 'validation-error') {
          let errorSlug = '';
          if (err?.response?.data?.data?.cpf?.length) {
            errorSlug = err?.response?.data?.data?.cpf?.[0];
            setErrorMessage(
              i18n.t([`error.registration.${errorSlug}`, 'error.unspecific']),
            );
          }
        } else {
          setErrorMessage(i18n.t('error.unspecific'));
        }
      } finally {
        setLoading(false);
      }
    } else {
      setErrorMessage(i18n.t('error.registration.invalid-cpf-numbers'));
    }
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    keysToRemoveOnLogout.forEach((key) => {
      localStorage.removeItem(key);
    });
    history.push('/signin');
  };

  return (
    <section className="relative size-full max-w-[600px] px-10 md:px-16">
      {validationError ? (
        <Container>
          <TwTitle
            size="xl"
            font="arboriaBold"
            color="shadow-500"
            classList="text-center"
          >
            <Trans
              i18nKey="registration.titleError"
              i18n={i18n}
              components={[<strong />]}
            />
          </TwTitle>
          <Paragraph textAlign="center" family="secondary">
            <Trans
              i18nKey="registration.validationErrorMessage"
              i18n={i18n}
              components={[<span />]}
            />
          </Paragraph>
        </Container>
      ) : (
        <Formik
          validationSchema={CPF_PHONE_VALIDATION_SCHEMA}
          initialValues={{
            cpf: '',
            phone: '',
          }}
          initialErrors={{
            cpf: 'Required',
            phone: 'Required',
          }}
        >
          {({ values, setFieldValue, setFieldTouched }) => {
            const checkCpf = () => {
              validateUserData(values);
            };

            const ButtonEnabled = checkCpfRegex(values.cpf);
            checkValidCpf(ButtonEnabled);
            return (
              <Form
                onChange={() => {
                  setErrorMessage('');
                }}
                className="flex flex-col gap-20 justify-between h-[90%]"
              >
                <div className="flex flex-col items-center gap-5 pt-10">
                  <TwTitle size="xl" color="white" classList="text-center">
                    <Trans
                      i18n={i18n}
                      i18nKey="validateData.title"
                      components={[<span className="text-grass-800" />]}
                    />
                  </TwTitle>
                  <p className="text-white text-xl max-w-full">
                    {i18n.t('validateData.message')}
                  </p>
                </div>

                {loading ? (
                  <div className="absolute left-0 h-5/6 bg-black w-full flex items-center flex-col justify-center gap-10">
                    <Loading />
                    {welcomeVpagShow && <WelcomeVpag />}
                  </div>
                ) : (
                  <>
                    <div className="flex flex-col">
                      <div className="flex items-center gap-1 mt-6 w-full">
                        <TwInput
                          id="documentNumber"
                          name="documentNumber"
                          type="text"
                          placeholder={i18n.t('registration.cpf')}
                          value={values.cpf}
                          disabled={loading}
                          numericKeypad
                          dataTestId="registration-input-cpf"
                          secondaryTheme
                          error={errorMessage}
                          handleChange={({ target: t }) => {
                            setFieldTouched('cpf', true);
                            setFieldValue('cpf', formatCpf(t.value));
                          }}
                          complement={
                            <ReactFlagsSelect
                              showSelectedLabel={false}
                              disabled
                              selected="BR"
                              selectedSize={30}
                              selectButtonClassName={`h-14 bg-ashes-700 w-50 m-0 rounded-lg ${
                                errorMessage ? 'border border-cherryRed' : ''
                              }`}
                            />
                          }
                        />
                      </div>
                    </div>
                    <div className="w-full">
                      <TwSubmitButton
                        id="validate-cpf-submit-btn"
                        before={i18n.t('registration.validate')}
                        after={i18n.t('registration.validate')}
                        isValid={ButtonEnabled}
                        isSubmitting={loading}
                        onClick={checkCpf}
                        classList="!text-xl"
                      />
                      <LogoutButton
                        className="!text-[18px]"
                        onClick={setShowLogoutWarning}
                      >
                        {i18n.t('topLeftMenu.logout')}
                      </LogoutButton>
                    </div>
                  </>
                )}
              </Form>
            );
          }}
        </Formik>
      )}
      <Modal
        showModal={showLogoutWarning}
        setShowModal={setShowLogoutWarning}
        height="200px"
      >
        <ConfirmAction
          invertButtonAction
          title={i18n.t('registration.logout.title')}
          description={
            <Trans
              i18n={i18n}
              i18nKey="registration.logout.description"
              components={[<span />]}
            />
          }
          confirmLabel={i18n.t('registration.logout.confirmLabel')}
          cancelLabel={i18n.t('registration.logout.cancelLabel')}
          closeModal={() => setShowLogoutWarning(false)}
          handleClick={handleLogout}
          confirmButtonId="complete-registration-button"
        />
      </Modal>
    </section>
  );
}

CpfValidationForm.propTypes = {
  onComplete: PropTypes.func.isRequired,
  checkValidCpf: PropTypes.func,
};

export default CpfValidationForm;
