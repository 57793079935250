import React from 'react';
import PropTypes from 'prop-types';

import i18n from '@/translate/i18n';
import { formatCurrency, formatShortDate } from '@/helpers/stringFormat';
import { Title } from '@/components/atoms/Typography/styles';

import {
  ContentWrapper,
  Text,
  TextWrapper,
  Value,
} from '@/components/molecules/TransactionResume/styles';

function WithdrawalResume({ data }) {
  const updatedAt = data?.updated_at && formatShortDate(data?.updated_at, true);

  return (
    <ContentWrapper>
      <TextWrapper>
        <Title position="left" size="xSmall" textColor="light" color="light">
          {i18n.t(`transactionHistory.${data.type}`)}{' '}
          <span>
            {data?.payment_method_name ?? data?.payment_method_slug ?? ' '}
          </span>
        </Title>
        <Text className="!text-left">{updatedAt}</Text>
      </TextWrapper>
      <Value>
        {data.status === 'completed' ? '- ' : ' '}
        {formatCurrency(data.customer_amount, data.customer_currency_iso)}
      </Value>
    </ContentWrapper>
  );
}

WithdrawalResume.propTypes = {
  data: PropTypes.shape({}),
}.isRequired;

export default WithdrawalResume;
