import React from 'react';
import PropTypes from 'prop-types';

export const TwModal = ({
  isOpen,
  onClose,
  children,
  classList,
  classListChildren,
  classListContainer,
  closeButtonWhite,
}) => {
  if (!isOpen) return null;

  return (
    <div
      className={`${classListContainer} fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50`}
    >
      <div
        className={` ${classList} bg-[#333333] rounded-lg shadow-lg w-full h-full  md:w-1/2 lg:w-3/6`}
      >
        <div className="flex justify-end items-center p-4">
          <button
            onClick={onClose}
            className={
              closeButtonWhite
                ? 'w-7 h-7 p-1 pb-3  bg-white border rounded-full  hover:bg-gray-700 hover:text-grass-900 transition-transform transform hover:scale-110 text-start text-4xl flex flex-col justify-center items-center'
                : 'text-grass-900 hover:text-gray-700 text-5xl'
            }
            type="button"
          >
            &times;
          </button>
        </div>
        <div className={`p-4 ${classListChildren}`}>{children}</div>
      </div>
    </div>
  );
};

TwModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  classList: PropTypes.string,
  classListChildren: PropTypes.string,
  classListContainer: PropTypes.string,
  closeButtonWhite: PropTypes.bool,
};
