import styled, { css } from 'styled-components';

export const Wrapper = styled.section`
  ${({ theme }) => css`
    align-items: center;
    animation: goRightDefault 0.6s;
    color: ${theme.colors.info.primary};
    display: flex;
    flex-direction: column;
    gap: 5vh;
    justify-content: start;
    width: 90%;

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      padding: 1rem 0;
      width: 50%;
    }
  `}
`;

export const TextWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
  gap: 2rem;
  padding: 0.8rem 0;

  p {
    max-width: 240px;
  }
`;

export const ContentIcons = styled.div`
  align-items: start;
  display: flex;
  justify-content: space-evenly;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-top: 20px;
  }
`;

export const CardIcon = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: start;
  flex-direction: column;
  max-width: 85px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    max-width: 110px;
  }

  img {
    width: 75px;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      width: 100px;
    }
  }

  h2 {
    font-size: ${({ theme }) => theme.font.size.small};
    text-align: center;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      font-size: ${({ theme }) => theme.font.size.base};
    }
  }
`;

export const LastTransactionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
