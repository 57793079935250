import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ExpandDiv from '@/components/atoms/ExpandDiv/styles';
import LoadingFullScreen from '@/components/molecules/LoadingFullScreen';
import ConversionDetails from '@/components/organisms/ConversionDetails';
import DepositDetails from '@/components/organisms/DepositDetails';
import MainTemplate from '@/components/templates/MainTemplate';
import TransferDetails from '@/components/organisms/TransferDetails';
import WithdrawalDetails from '@/components/organisms/WithdrawalDetails';
import i18n from '@/translate/i18n';
import { getTransaction } from '@/services/api';

import { ErrorText } from './styles';

function TransactionStatus({ match }) {
  const { transactionId } = match.params;
  const [data, setData] = useState({});
  const [requestError, setRequestError] = useState(false);
  const [errorMessage, setErroMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const getDepositInfo = useCallback(
    async (signal) => {
      try {
        setIsLoading(true);
        const res = await getTransaction(transactionId, signal);

        setData(res.data);
      } catch (err) {
        const message = err?.response?.data?.message;
        setRequestError(true);
        setErroMessage(
          i18n.t([
            `error.transactionStatus.${message}`,
            'error.transactionStatus.default',
          ]),
        );
      } finally {
        setIsLoading(false);
      }
    },
    [transactionId],
  );

  useEffect(() => {
    const controller = new AbortController();

    getDepositInfo(controller.signal);

    return () => controller.abort();
  }, [getDepositInfo, transactionId]);

  return (
    <MainTemplate
      headerProps={{
        title: i18n.t([`transactions.detailsTitle.${data?.type}`, '']),
        returnTo: '/wallet',
      }}
    >
      {isLoading && (
        <>
          <ExpandDiv />
          <LoadingFullScreen />
        </>
      )}
      <>
        {data.type === 'deposit' && (
          <DepositDetails
            data={data}
            reloadAction={getDepositInfo}
            setData={setData}
          />
        )}
        {data.type === 'withdrawal' && (
          <WithdrawalDetails data={data} reloadAction={getDepositInfo} />
        )}
        {(data.type === 'transfer-in' || data.type === 'transfer-out') && (
          <TransferDetails data={data} />
        )}
        {data.type === 'conversion' && <ConversionDetails data={data} />}
      </>
      {requestError && <ErrorText>{errorMessage}</ErrorText>}
    </MainTemplate>
  );
}

TransactionStatus.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      transactionId: PropTypes.string,
    }),
  }),
}.isRequired;

export default TransactionStatus;
