import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Trans } from 'react-i18next';
import MainTemplate from '@/components/templates/MainTemplate';
import { getListMerchantsCashback, getMerchant } from '@/services/api';
import BackButton from '@/components/atoms/BackButton';

import useDeviceWidth from '@/hooks/useDeviceWidth';

import { TextButton } from '@/components/atoms/Buttons/TextButton/styles';
import { Paragraph } from '@/components/atoms/Typography/styles';
import InputCopy from '@/components/atoms/Inputs/InputCopy';
import { ContainerContent } from '../Cashback/styles';
import TitleModal from '@/components/molecules/TitleModal';
import { formatCurrency } from '@/helpers/stringFormat';
import { useWalletData } from '@/context/walletContext';
import i18n from '@/translate/i18n';
import Modal from '@/components/molecules/Modal';
import { Title } from '@/components/molecules/WalletHeader/style';
import { Button } from '@/components/atoms/Buttons/Button/styles';
import CashbackTutorialModal from '@/components/organisms/CashbackTutorialModal';
import { STATUS_AFFILIATE } from '@/helpers/constants';
import IconVplus from '@/components/molecules/IconVplus';
import {
  BackButtonContainer,
  BackButtonContent,
  InfoMarchantsContainer,
  MerchanDiscContainer,
  ModalContent,
  ParagraphBolder,
  ParagraphStyled,
  SpanStyled,
  TextButtonStyled,
  TitleStyled,
} from './styles';
import MerchanDisc from '@/components/atoms/MerchanDisco';

const ZERO = 0;
const AFFILIATED = 'affiliated';
const VPLUS = 'vplus';
const VPAG = 'Vpag';
const CASHBACK_PIX = 'cashback-pix';
const GENERAL = 'general';
const EMPTY_STRING = '';
const ONE_HUNDRED = 100;
const HEIGHT_SEE_DETAILS_MODAL = '376px';
const WIDTH_SEE_DETAILS_MODAL = '528px';

function CashbackDetail() {
  const {
    walletData: { selectedWallet },
  } = useWalletData();
  const { idMerchant } = useParams();
  const [merchant, setMerchant] = useState({});
  const { isMobileLayout } = useDeviceWidth();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [pixKey, setPixKey] = useState('');
  const [infoCashback, setInfoCashback] = useState({});
  const [showModalSeeDetails, setShowModalseeDetails] = useState(false);
  const [showModalTutorial, setShowModalTutorial] = useState(false);
  const [affiliated, setAffiliated] = useState(false);

  const handleCloseModalTutorial = () => {
    setShowModalTutorial(false);
  };

  const handleCloseModalSeeDetails = () => {
    setShowModalseeDetails(false);
  };

  const getInfoMerchant = async (item) => {
    setIsLoading(true);
    const res = await getMerchant(item);
    const marchantsCashbacks = await getListMerchantsCashback();
    const marchant = marchantsCashbacks.data.filter(
      (element) => String(element.id) === String(item),
    );
    setIsLoading(false);
    return { ...res.data, info: marchant[ZERO] };
  };

  const handleBackStep = () => {
    history.push('/cashback/merchants-actives');
  };
  const isObjectEmpty = (obj) => Object.keys(obj).length === ZERO;

  useEffect(async () => {
    setMerchant(await getInfoMerchant(idMerchant));
  }, []);

  useEffect(() => {
    if (isObjectEmpty(merchant)) return;
    const cashbacks = merchant.info.cashbacks.filter(
      (element) => element.transaction_type === CASHBACK_PIX,
    );
    if (merchant.info.usernames[ZERO]?.affiliate_status === AFFILIATED) {
      setInfoCashback(
        cashbacks.find((element) => element.user_type === VPLUS) || '',
      );
    } else {
      setInfoCashback(
        cashbacks.find((element) => element.user_type === GENERAL) || '',
      );
    }
    setPixKey(merchant?.info.pix_key || '');
  }, [merchant]);

  useEffect(() => {
    if (isObjectEmpty(merchant)) return;
    setAffiliated(
      STATUS_AFFILIATE === merchant.info.usernames[ZERO]?.affiliate_status,
    );
  }, [merchant]);

  return (
    <MainTemplate
      containerProps={{ gradient: true }}
      headerProps={{ mobileFullVersion: false }}
    >
      <BackButtonContainer>
        {!isMobileLayout && (
          <BackButtonContent>
            <BackButton to="/cashback/merchants-actives" text="Voltar" />
          </BackButtonContent>
        )}
      </BackButtonContainer>
      <ContainerContent isMobileLayout={isMobileLayout}>
        {isMobileLayout && (
          <>
            <TitleModal handleBackStep={handleBackStep} />
          </>
        )}
        <InfoMarchantsContainer>
          <MerchanDiscContainer>
            <div>{affiliated && <IconVplus white />}</div>
            <MerchanDisc
              src={
                affiliated
                  ? merchant.user_vplus_image_url
                    ? merchant.user_vplus_image_url
                    : merchant.image_url
                  : merchant.image_url
              }
            />
          </MerchanDiscContainer>
          <TitleStyled
            color="light"
            fontSize={isMobileLayout ? '18px' : '32px'}
          >
            {merchant.name}
          </TitleStyled>
          <Paragraph>
            <Trans
              i18n={i18n}
              i18nKey="cashbackScreenDetail.typeCashback"
              components={[<SpanStyled />]}
              values={{
                typeCashback: infoCashback.user_type
                  ? VPAG
                  : 'Simples' || EMPTY_STRING,
              }}
            />
          </Paragraph>
          <TextButton onClick={() => setShowModalseeDetails(true)}>
            {i18n.t('cashbackScreenDetail.seeDetails')}
          </TextButton>
        </InfoMarchantsContainer>
        <TitleStyled size="large">
          {!isObjectEmpty(infoCashback) && (
            <>
              <Trans
                i18n={i18n}
                i18nKey="cashbackScreenDetail.winCashback"
                components={[<span />]}
                values={{
                  percent: infoCashback.percent * ONE_HUNDRED,
                  limit: formatCurrency(infoCashback.limit, selectedWallet),
                }}
              />
            </>
          )}
        </TitleStyled>
        <Paragraph>{i18n.t('cashbackScreenDetail.transferPix')}</Paragraph>
        {!isLoading && <InputCopy value={pixKey} />}
        <TextButtonStyled onClick={() => setShowModalTutorial(true)}>
          {i18n.t('cashbackScreenDetail.howUseKey')}
        </TextButtonStyled>
        <Modal
          centralized
          handleClose={handleCloseModalSeeDetails}
          showModal={showModalSeeDetails}
          height={HEIGHT_SEE_DETAILS_MODAL}
          width={WIDTH_SEE_DETAILS_MODAL}
          hiddenCloseButton={isMobileLayout}
        >
          <ModalContent>
            <Title>
              <Trans
                i18n={i18n}
                i18nKey="cashbackSeeDetailsModal.title"
                values={{ merchantName: merchant.name }}
              />
            </Title>
            <ParagraphStyled>
              <Trans
                i18n={i18n}
                i18nKey="cashbackSeeDetailsModal.firstParagraph"
                components={<span />}
              />
            </ParagraphStyled>
            {infoCashback.percent && infoCashback.limit && (
              <ParagraphBolder>
                <Trans
                  i18n={i18n}
                  i18nKey="cashbackSeeDetailsModal.advantages"
                  values={{
                    percent: infoCashback.percent * ONE_HUNDRED,
                    limit: formatCurrency(infoCashback.limit, selectedWallet),
                  }}
                />
              </ParagraphBolder>
            )}

            <Button onClick={handleCloseModalSeeDetails}>
              {i18n.t('cashbackSeeDetailsModal.back')}
            </Button>
          </ModalContent>
        </Modal>
        <CashbackTutorialModal
          handleClose={handleCloseModalTutorial}
          showModal={showModalTutorial}
        />
      </ContainerContent>
    </MainTemplate>
  );
}

export default CashbackDetail;
