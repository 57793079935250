import axios from 'axios';

import {
  apiIbge,
  apiViaCep,
  apiMerchant as merchant,
  apiPayment as payment,
  apiPromotion as promotion,
  apiUser as user,
  apiWallet as wallet,
} from '@/config/axios';

//   ---------- USER -----------

export const checkIsAuthenticated = async () => {
  const token = JSON.parse(localStorage.getItem('accessToken'));

  if (!token) {
    return null;
  }

  try {
    const authString = `Bearer ${JSON.parse(
      localStorage.getItem('accessToken'),
    )}`;
    return await axios.get(
      `${process.env.REACT_APP_USER_API_URL}/user/current`,
      {
        headers: {
          Authorization: authString,
        },
      },
    );
  } catch (err) {
    return null;
  }
};

export const login = async (payload) =>
  user.post('/user/login', payload).then((res) => res.data);

export const getUserFitBankAccount = (signal) =>
  user.get('/wallet/customer-bank-account/current', signal);

export const loginForTransaction = async (payload, id) =>
  user.post(`/user/transaction/login/${id}`, payload).then((res) => res.data);

export const getTransactionByUuid = async (uuid) =>
  user.get(`/user/transaction/logged/${uuid}`).then((res) => res.data);

export const recoverPassword = async (payload) =>
  user.post('/user/password/recover', payload).then((res) => res.data);

export const updatePassword = async (payload) =>
  user.post('/user/password/update', payload).then((res) => res.data);

export const retryValidation = async () =>
  user.post('/user/kyc/onboarding-retry').then((res) => res.data);

export const updatePasswordConfig = async (payload) =>
  user.put('/user/password/config/update', payload).then((res) => res.data);

export const createUser = async (payload) =>
  user.post('/user', payload).then((res) => res.data);

export const getUserInfo = async () =>
  user.get('/user/current').then((res) => res.data);

export const registrationCpf = async (payload) =>
  user.post('/user/cpf_registration', payload).then((res) => res.data);

export const completeRegistration = async (payload) =>
  user.put('/user/complete_registration', payload).then((res) => res.data);

export const sendIdwToken = async (id, sdkToken) =>
  user.post(`/user/send_token/${id}/${sdkToken}`).then((res) => res.data);

export const ocrValidation = async (payload) =>
  user.post('/user/kyc/ocr-validation', payload).then((res) => res.data);

export const validateEmail = async (token) =>
  user.post(`/user/validation_email/${token}`).then((res) => res.data);

export const validateEmailWithCode = async (payload) =>
  user.post('/user/validation_email', payload).then((res) => res.data);

export const resendEmail = async (payload) =>
  user.post(`/user/resend_email`, payload).then((res) => res.data);

export const getUserLimits = async (userId, signal) =>
  user.get(`/user/${userId}/limits`, { signal }).then((res) => res.data);

export const getUserLimitsFull = async (params, signal) =>
  user
    .get('/user/transaction-limits', { params, signal })
    .then((res) => res.data);

export const getUserKyc = async (userId, signal) =>
  user.get(`/user/${userId}/level`, { signal }).then((res) => res.data);

export const getUserValidation = async (userId, signal) =>
  user.get(`/user/validation/${userId}`, { signal }).then((res) => res.data);

export const getUserAddress = async (userId, signal) =>
  user.get(`/user/${userId}/address`, { signal }).then((res) => res.data);

export const createUserAddress = async (payload) =>
  user.post('/user/address', payload).then((res) => res.data);

export const updateUserAddress = async (addressId, payload) =>
  user.put(`/user/address/${addressId}`, payload).then((res) => res.data);

export const createPhoneValidation = async (userId, signal) =>
  user
    .get(`/user/phone/verification/${userId}`, { signal })
    .then((res) => res.data);

export const updatePhoneNumber = async (userId, payload) =>
  user.put(`/user/${userId}/phone`, payload).then((res) => res.data);

export const validatePhoneNumber = async (userId, payload) =>
  user.post(`/user/phone/check/${userId}`, payload).then((res) => res.data);

export const updateUserNotification = async (userId, payload) =>
  user.put(`/user/notification/${userId}`, payload).then((res) => res.data);

export const getInterests = async (params, signal) =>
  user.get(`/user/interest`, { params, signal }).then((res) => res.data);

export const updateUserInterests = async (userId, payload) =>
  user.put(`/user/${userId}/interest`, payload).then((res) => res.data);

export const updateProfileImage = async (payload) =>
  user.post('/user/profile-image', payload).then((res) => res.data);

export const createUserDocument = async (payload) =>
  user.post('/user/kyc-documents', payload).then((res) => res.data);

//   ---------- WALLET -----------

export const createDeposit = async (payload, signal) =>
  wallet.post(`/wallet/deposit`, payload, { signal }).then((res) => res.data);

export const createWithdrawal = async (payload) =>
  wallet.post(`/wallet/withdrawal`, payload).then((res) => res.data);

export const createTransferIn = async (payload, signal) =>
  wallet
    .post(`/wallet/transfer-in-vsi`, payload, { signal })
    .then((res) => res.data);

export const createConversion = async (payload, signal) =>
  wallet
    .post(`/wallet/conversion`, payload, { signal })
    .then((res) => res.data);

export const cancelTransaction = async (transactionId) =>
  wallet.put(`/wallet/transaction/${transactionId}/cancel`).then((res) => res);

export const getTransaction = async (transactionId, signal) =>
  wallet
    .get(`/wallet/transaction/${transactionId}`, { signal })
    .then((res) => res.data);

export const getMyKeys = async () =>
  wallet.get(`/wallet/pix-keys`).then((res) => res.data);

export const getLastTransactions = async (payload, signal) =>
  wallet
    .post('/wallet/transactions/minimal', payload, {
      signal,
    })
    .then((res) => res.data);

export const listTransactions = async (params, signal) =>
  wallet
    .get('/wallet/transactions', {
      params,
      signal,
    })
    .then((res) => res.data);

export const listCustomerWallets = async (customerId, signal) =>
  wallet
    .get(`/wallet/customer/${customerId}`, { signal })
    .then((res) => res.data);

//   ---------- MERCHANT -----------

export const confirmTransactionApi = async (payload, uuid, signal) =>
  merchant
    .patch(`/merchant/api/transaction/${uuid}`, payload, {
      signal,
    })
    .then((res) => res.data);

export const listMerchants = async (payload, page, signal) =>
  merchant
    .post(`/merchant/merchants?page=${page || 1}`, payload, { signal })
    .then((res) => res.data);

export const getAvailableMerchants = async (customerId, params, signal) =>
  merchant
    .get(
      `/merchant/customer-restricted/${customerId}/transfer-in/merchants-available`,
      { params, signal },
    )
    .then((res) => res.data);

export const getTrackerByMerchant = async (merchantId, signal) =>
  merchant
    .get(`/merchant/trackers?merchant_ids=${merchantId}`, {
      signal,
    })
    .then((res) => res.data);

export const listDefaultTrackers = async (signal) =>
  merchant
    .get('/merchant/trackers?vertical_slugs=default&active=1&per_page=200', {
      signal,
    })
    .then((res) => res.data);

export const listUsernames = async (userId, signal) =>
  merchant
    .get(`/merchant/usernames?customer_ids[]=${userId}`, {
      signal,
    })
    .then((res) => res.data);

export const getAnnualBalance = async (signal) =>
  merchant.get('/wallet/my-annual-balance', { signal }).then((res) => res.data);

export const getListMerchantsCashback = async (signal) =>
  merchant.get('/merchant/merchants/with-cashback').then((res) => res.data);

export const getMerchant = async (id, signal) =>
  merchant.get(`/merchant/merchant/${id}`, { signal }).then((res) => res.data);
//   ---------- PROMOTION -----------

export const getUserBonuses = async (userId, params = '', signal) =>
  user
    .get(`/promotion/bonuses/users/${userId}?${params}`, { signal })
    .then((res) => res.data);

export const getBonusHistory = async (params, signal) =>
  user
    .get(`/promotion/bonuses/user-bonuses/report`, { params }, signal)
    .then((res) => res.data);

export const listPromotions = async (params) =>
  promotion.get('/promotion/promotions', { params }).then((res) => res.data);

export const getPromocode = async () =>
  promotion.get(`/user/current-user/promocode`).then((res) => res.data);

export const getUserPoints = async (userId) =>
  promotion.get(`/promotion/user-points/${userId}`).then((res) => res.data);

export const listUserPointsRecord = async (params, signal) =>
  promotion
    .get('/promotion/user-points/record', { params }, signal)
    .then((res) => res.data);

export const listUserCampaigns = async (signal) =>
  promotion
    .get('/promotion/campaign/get-by-customer', signal)
    .then((res) => res.data);

//   ---------- PAYMENT GATEWAY -----------

export const getPaymentMethods = async (signal) =>
  payment
    .post('/paymentgateway/payment-methods', {}, { signal })
    .then((res) => res.data);

//   ---------- EXCHANGE -----------

export const getConversionData = async (payload, signal) =>
  wallet
    .post(`/exchange/conversion`, payload, { signal })
    .then((res) => res.data);

export const getExchangeRate = async (params, signal) =>
  wallet.get('/exchange/rate', { params, signal }).then((res) => res.data);

//   ---------- MISC -----------

export const getFaqGroups = async (params, signal) =>
  wallet.get(`/misc/faqs/groups?${params}`, { signal }).then((res) => res.data);

export const getTermsBySlug = async (slug) =>
  user.get(`/misc/term-and-conditions/${slug}`).then((res) => res.data);

//   ---------- API VIA CEP -----------

export const getAddressByPostalCode = async (postalCode) =>
  apiViaCep.get(`/ws/${postalCode}/json`).then((res) => res.data);

//   ---------- API IBGE -----------

export const getCitiesByState = async (state, signal) =>
  apiIbge
    .get(`/api/v1/localidades/estados/${state}/municipios`, { signal })
    .then((res) => res.data);
