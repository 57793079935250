import styled, { css } from 'styled-components';
import { Button } from '@/components/atoms/Buttons/Button/styles';
import { Paragraph, Title } from '@/components/atoms/Typography/styles';
import { TitleContainer } from '../Cashback/styles';
import { TextButton } from '@/components/atoms/Buttons/TextButton/styles';

export const ActiveMechButton = styled(Button)`
  font-size: 20px;
  max-height: 60px;
  max-width: 320px;
  min-height: 60px;
  min-width: 320px;
  padding: 10px;
  transition: opacity 0.2s;
  visibility: ${({ loading }) => (loading ? 'hidden' : 'auto')};

  :hover {
    opacity: 0.8;
  }
`;

export const TitleContainerChooseCash = styled(TitleContainer)`
  width: 50%;
`;

export const BackButtonContainer = styled.div`
  padding: 0.625rem 0rem 3.125rem 3.75rem;
  width: 100%;

  @media (min-width: 1440px) {
    margin: 1.875rem 0 1.75rem;
  }
`;

export const BackButtonContent = styled.div`
  width: 'fit-content';
`;
export const CenterContainer = styled.div`
  margin-top: -25px;
  width: 690px;
`;

export const TitleStyled = styled(Title)`
  ${({ theme }) => css`
    color: white;
    font-size: 32px;

    span {
      color: ${theme.colors.blue};
    }
  `};
`;

export const InfoMarchantsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  height: fit-content;
`;

export const SpanStyled = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.light} !important;
  `};
`;

export const ModalContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow-y: auto;

  h1 {
    font-size: 26px;
  }
`;

export const ParagraphStyled = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.light};
  font-size: 22px;
  font-weight: 50;

  span {
    color: ${({ theme }) => theme.colors.light};
    font-size: 22px;
    font-weight: 800;
  }
`;

export const ParagraphBolder = styled(ParagraphStyled)`
  font-weight: 800;
`;

export const ContainerContent = styled.div`
  ${({ isMobile }) => css`
    border: 1px solid green;
    display: flex;
    flex-direction: ${isMobile ? 'column' : 'row'};
    justify-content: space-around;
    position: absolute;
    top: 0px;
    z-index: 12;
  `};
`;

export const MerchanDiscContainer = styled.div`
  position: relative;
  height: fit-content;

  > div:first-child {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: -40px;
    z-index: 3;
  }
`;

export const TextButtonStyled = styled(TextButton)`
  margin-bottom: 1.875rem;
`;
