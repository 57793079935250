import styled from 'styled-components';

export const MethodWrapper = styled.div`
  align-items: center;
  animation: goDownDefault 1s;
  display: flex;
  flex-direction: column;
  margin: 10px;
  text-align: center;
  text-transform: capitalize;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: none;
  }
`;

export const ValueWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1.5;
  flex-direction: column;
  justify-content: center;
  margin: 5vh 0;
`;

export const ValueText = styled.h1`
  color: ${({ color, theme }) => color || theme.colors.primary};
  font: ${({ theme }) =>
    `${theme.font.size.xxxLarge} ${theme.font.family.tertiary}`};
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.info.light};
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: 400;
  text-align: center;
  width: 300px;
`;

export const ErrorText = styled(Text)`
  color: ${({ theme }) => theme.colors.danger};
  font-size: ${({ theme }) => theme.font.size.xSmall};
`;

export const MethodName = styled.p`
  color: ${({ theme }) => theme.colors.info.light};
  font-size: ${({ theme }) => theme.font.size.medium};
  margin-top: 5px;
`;

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 90%;
  padding: 0 1rem;
  justify-content: space-evenly;
  position: relative;
  width: 100%;

  svg {
    margin: 2rem 0;
  }
`;

export const HideInDesktop = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.info.light};
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;

  > * {
    &:first-child {
      margin-bottom: 10px;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: none;
  }
`;

export const HideInMobile = styled.div`
  display: none;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: block;
  }
`;

export const DeskWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    background: ${({ theme, background }) =>
      background ||
      `linear-gradient(
        90deg,
        ${theme.colors.bgGradientDark.start} 0%,
        ${theme.colors.bgGradientDark.end} 100%
        )`};
    height: 100%;
    justify-content: space-between;
    min-height: 550px;
  }
`;

export const TextMedium = styled.p`
  display: none;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    color: ${({ theme }) => theme.colors.info.light};
    display: inline;
    font-size: ${({ theme }) => theme.font.size.medium};
  }
`;

export const PleaseWaitText = styled.p`
  color: ${({ theme }) => theme.colors.info.light};
  display: inline;
  font-size: ${({ theme }) => theme.font.size.small};
  text-align: center;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    bottom: 20px;
    color: ${({ theme }) => theme.colors.info.light};
    display: inline;
    font-size: ${({ theme }) => theme.font.size.small};
    position: absolute;
    right: 40px;
  }
`;

export const IconWrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.bg.tertiary};
  border-radius: 50%;
  display: flex;
  height: 70px;
  justify-content: center;
  width: 70px;
`;
