import React from 'react';
import PropTypes from 'prop-types';
import i18n from '@/translate/i18n';

import { formatShortDate } from '@/helpers/stringFormat';

const BACKGROUND_POINTS = {
  available: 'bg-grass-800',
  out: 'bg-blue-secondary',
  used: 'bg-sand-800',
  expired: 'bg-cherryRed',
};

function BonusHistoryResume({ data, setModalContent }) {
  return (
    <div className="flex items-center justify-between w-full h-12 min-h-12 last:border-b-0 border-b border-shadow-500">
      <span className="w-1/2 text-left text-xs ml-3">{data?.bonus?.title}</span>
      <span className="w-1/5 text-left  text-xs">
        {formatShortDate(data?.created_at, false)}
      </span>
      <div
        className={`w-1 h-1 rounded-full mx-2 ${
          BACKGROUND_POINTS[data?.status]
        }`}
      />
      <span className="w-1/6 text-left text-xs capitalize">
        {i18n.t(`bonus.status.${data?.status}`)}
      </span>
      <button
        className="w-10 h-10 flex items-center justify-center"
        type="button"
        onClick={setModalContent}
      >
        <img src="/icons/contextMenu.svg" alt="menu" />
      </button>
    </div>
  );
}

BonusHistoryResume.propTypes = {
  data: PropTypes.shape({}),
}.isRequired;

export default BonusHistoryResume;
