import React, { useState } from 'react';
import PropTypes from 'prop-types';

import CheckboxInput from '@/components/atoms/Checkbox';
import DateRangePicker from '@/components/molecules/DateRangePicker';
import Select from '@/components/atoms/Select';
import i18n from '@/translate/i18n';
import { Paragraph } from '@/components/atoms/Typography/styles';
import { Button } from '@/components/atoms/Buttons/Button/styles';
import { TextButton } from '@/components/atoms/Buttons/TextButton/styles';

import {
  ButtonsWrapper,
  CheckBoxWrapper,
  Container,
  Content,
  DatesWrapper,
  InputWrapper,
} from './styles';

const statusOptions = [
  { label: i18n.t('transactionHistory.all'), value: 'all' },
  { label: i18n.t('transactionHistory.pending'), value: 'pending' },
  {
    label: i18n.t('transactionHistory.completed'),
    value: 'completed',
  },
  {
    label: i18n.t('transactionHistory.cancelled'),
    value: 'cancelled',
  },
  {
    label: i18n.t('transactionHistory.expired'),
    value: 'expired',
  },
];

function HistoryFilter({
  currentFilters,
  handleSubmit,
  initialFilter,
  setCurrentFilters,
  setShowFilter,
  showFilter,
}) {
  const [newFilters, setNewFilters] = useState(currentFilters);

  const handleChange = ({ name, checked }) => {
    const { type } = newFilters;

    if (checked) {
      setNewFilters({ ...newFilters, type: [...type, name] });
    } else if (type.length > 1) {
      setNewFilters({
        ...newFilters,
        type: type.filter((el) => el !== name),
      });
    }
  };

  const resetFilter = () => {
    setCurrentFilters(initialFilter);
    setNewFilters(initialFilter);
  };

  const isDirty = JSON.stringify(currentFilters) !== JSON.stringify(newFilters);
  const isFiltered =
    JSON.stringify(newFilters) !== JSON.stringify(initialFilter);
  const filtersChanged =
    JSON.stringify(currentFilters) !== JSON.stringify(initialFilter);

  return (
    <>
      <Container showFilter={showFilter} id="transactions-filter">
        <Content>
          <CheckBoxWrapper>
            <CheckboxInput
              id="deposit"
              name="deposit"
              label={i18n.t('transactionHistory.deposit')}
              checked={newFilters?.type.includes('deposit')}
              onChange={({ target }) => handleChange(target)}
            />
            <CheckboxInput
              id="withdrawal"
              name="withdrawal"
              label={i18n.t('transactionHistory.withdrawal')}
              checked={newFilters?.type.includes('withdrawal')}
              onChange={({ target }) => handleChange(target)}
            />
            <CheckboxInput
              id="transfer-in"
              name="transfer-in"
              label={i18n.t('transactionHistory.transfer-in')}
              checked={newFilters?.type.includes('transfer-in')}
              onChange={({ target }) => handleChange(target)}
            />
            <CheckboxInput
              id="transfer-out"
              name="transfer-out"
              label={i18n.t('transactionHistory.transfer-out')}
              checked={newFilters?.type.includes('transfer-out')}
              onChange={({ target }) => handleChange(target)}
            />
          </CheckBoxWrapper>
          <InputWrapper>
            <DatesWrapper>
              <Paragraph family="secondary" color="title">
                {i18n.t('transactionHistory.dateRange')}
              </Paragraph>
              <DateRangePicker setState={setNewFilters} filters={newFilters} />
            </DatesWrapper>
            <Select
              options={statusOptions}
              id="status"
              value={newFilters.status}
              onChange={({ target: t }) => {
                setNewFilters({ ...newFilters, status: [t.value] });
              }}
              width="120px"
              color="light"
              variant="rounded"
            />
            <Paragraph family="secondary" color="title">
              {i18n.t('transactionHistory.status')}
            </Paragraph>
          </InputWrapper>
          {isFiltered && (
            <ButtonsWrapper>
              <Button
                type="button"
                disabled={!isDirty}
                onClick={() => {
                  setShowFilter(false);
                  setCurrentFilters(newFilters);
                  handleSubmit(newFilters, null);
                }}
              >
                {i18n.t('transactionHistory.apply')}
              </Button>
              <TextButton
                type="button"
                onClick={() => {
                  resetFilter();
                  setShowFilter(false);
                  if (filtersChanged) {
                    handleSubmit(initialFilter, null);
                  }
                }}
              >
                {i18n.t('transactionHistory.clear')}
              </TextButton>
            </ButtonsWrapper>
          )}
        </Content>
      </Container>
    </>
  );
}

HistoryFilter.propTypes = {
  setCurrentFilters: PropTypes.func,
  setShowFilter: PropTypes.func,
  filterParams: PropTypes.shape({
    type: PropTypes.string,
    status: PropTypes.string,
    startAt: PropTypes.string,
    endDate: PropTypes.string,
  }),
}.isRequired;

export default HistoryFilter;
