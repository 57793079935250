import React from 'react';
import PropTypes from 'prop-types';

import i18n from '@/translate/i18n';
import { twTitleColorByStatus } from '@/helpers/constants';

function TwDetailsLine({
  amount = '',
  font = 'arboriaBold',
  bonus,
  info,
  size,
  divider = false,
  classList = '',
  colorStatus,
}) {
  const sizes = {
    sm: 'text-sm',
    base: 'text-base',
    lg: 'text-lg',
  };

  const fonts = {
    arboriaLight: 'font-arboria-light',
    arboriaRegular: 'font-arboria-regular',
    arboriaBold: 'font-arboria-bold',
  };

  const isFreeBonus =
    bonus?.type === 'fixed' && bonus?.min_amount_transaction > 0;

  const message = bonus
    ? [`bonusTitle.${isFreeBonus ? 'free' : bonus?.type}`, 'bonus.bonus']
    : info;

  return (
    <>
      <div
        className={`grid grid-cols-2fr-1fr gap-x-8 min-w-[280px] pt-0 ${
          sizes[size] || 'text-base'
        } ${fonts[font]} ${classList} ${
          divider ? 'border-t border-t-shadow-500 pt-4' : 'border-t-0'
        }`}
      >
        <div className="flex items-center">
          <p>{i18n.t(message)}</p>
        </div>

        <p
          className={`text-right uppercase ${
            colorStatus && twTitleColorByStatus[colorStatus ?? 'default']
          }`}
        >
          {amount}
        </p>
      </div>
    </>
  );
}

TwDetailsLine.propTypes = {
  amount: PropTypes.string.isRequired,
  divider: PropTypes.bool,
  info: PropTypes.string,
  colorStatus: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  font: PropTypes.string,
  classList: PropTypes.string,
  bonus: PropTypes.shape({
    type: PropTypes.string.isRequired,
    min_amount_transaction: PropTypes.number.isRequired,
  }).isRequired,
};

export default TwDetailsLine;
