import styled, { css } from 'styled-components';
import DatePicker from 'react-multi-date-picker';
import { hexToRGBA } from '@/styles/colors';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    color: white;

    input {
      background-color: ${hexToRGBA(theme.colors.shadow, 0.2)};
      border: none;
      border-radius: 40px;
      color: ${theme.colors.info.light};
      font: ${theme.font.size.small} ${theme.font.family.primary};
      height: 40px;
      text-align: center;
      width: 250px;

      &::placeholder {
        color: ${theme.colors.info.inactive};
      }
    }

    .rmdp-week-day,
    .rmdp-arrow {
      color: ${theme.colors.primary};
    }

    .rmdp-arrow {
      border-color: ${theme.colors.primary} !important;
    }

    .rmdp-arrow-container {
      &:hover {
        background-color: ${hexToRGBA(theme.colors.bg.dark, 0.5)} !important;
      }
    }

    .rmdp-header-values,
    .rmdp-day {
      color: white;
    }

    .rmdp-range {
      background-color: ${theme.colors.bg.dark};
    }

    .rmdp-today {
      span {
        background-color: ${hexToRGBA(theme.colors.bg.dark, 0.5)};
      }
    }
  `}
`;

export const DateRangePickerInput = styled(DatePicker)`
  background: linear-gradient(0deg, #474a4a 0%, #474a4a 100%), #1e1f1f;
  border-radius: 27px;
  box-shadow: none;
  color: white !important;
`;
