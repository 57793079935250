import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { IconStyled, Wrapper } from './styles';

function HistoryIcon({ type, status, width, opacity }) {
  const [isError, setIsError] = useState(false);

  return (
    <Wrapper width={width}>
      {!isError && (
        <IconStyled
          src={`/icons/transactions/${type}-${status}.svg`}
          width={width}
          height={width}
          onError={setIsError}
          opacity={opacity}
        />
      )}
    </Wrapper>
  );
}

HistoryIcon.propTypes = {
  type: PropTypes.string,
  status: PropTypes.string,
  width: PropTypes.string,
  opacity: PropTypes.number,
}.isRequired;

export default HistoryIcon;
