import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { useHistory, useParams } from 'react-router-dom';
import { Trans } from 'react-i18next';

import BonusSelector from '@/components/organisms/BonusSelector';
import ChangeBonusButton from '@/components/atoms/Buttons/ChangeBonus';
import DesktopTransferForm from '@/components/organisms/DesktopTransferForm';
import GetStartWithPoints from '@/components/organisms/GetStartWithPoints';
import Loading from '@/components/atoms/Loading';
import MainTemplate from '@/components/templates/MainTemplate';
import MerchantIcon from '@/components/atoms/MerchantIcon';
import { HideInMobile } from '@/components/atoms/GenerateTransactionStyle/styles';
import TransactionPreview from '@/components/molecules/TransactionPreview';
import i18n from '@/translate/i18n';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import useMerchants from '@/hooks/useMerchants';
import useUserBonuses from '@/hooks/useUserBonuses';
import { PaginationWrapper } from '../Transactions/styles';
import {
  checkIfMerchantHasBonuses,
  filterMerchantHasBonuses,
} from '@/helpers/functions';
import { useUserData } from '@/context/userContext';
import { useWalletData } from '@/context/walletContext';

import {
  Arrow,
  ArrowButton,
  BonusIcon,
  ContentWrapper,
  LogoWrapper,
  Merchant,
  MerchantsWrapper,
  ReverseWrapper,
  Section,
  SectionDivider,
  Spacer,
} from './styles';
import TwTitle from '@/components/atoms/TwTitle';
import TwBonusIndicator from '@/components/atoms/TwBonusIndicator';

const FIRST_PAGE = 1;
const NUM_OF_PAGES_DEFAULT = 1;
const REQUEST_DELAY_IN_MS = 150;
const LOGO_OPACITY = 0.5;
const ICON_SIZE = 30;

function SelectMerchant() {
  const history = useHistory();
  const { isMobileLayout } = useDeviceWidth();

  const [pageNumber, setPageNumber] = useState(FIRST_PAGE);
  const [pageCount, setPageCount] = useState(NUM_OF_PAGES_DEFAULT);
  const [data, setData] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const { merchantSlug } = useParams();

  const {
    setWalletData,
    walletData: {
      merchants,
      selectedMerchant,
      selectedBonus,
      filteredMerchantsByBonus,
      isMerchantsFilteredByBonus,
      transactionSubmitError,
      initialSelectedMerchant,
    },
  } = useWalletData();

  const { getMoreData, loadingMoreData, loadingMerchants } = useMerchants(
    pageNumber,
    setPageCount,
    { id: filteredMerchantsByBonus },
  );

  const { isFetching: isFetchingBonuses } = useUserBonuses();
  const { userData } = useUserData();

  const handleClick = (merchant) => {
    if (!isMobileLayout) {
      setWalletData((prevState) => ({
        ...prevState,
        selectedMerchant: merchant,
        ...(filteredMerchantsByBonus || isMerchantsFilteredByBonus
          ? {}
          : { selectedBonus: null }),
      }));
      window.history.replaceState({}, '', `/transfer/${merchant?.slug}`);
    } else {
      setWalletData((prevState) => ({
        ...prevState,
        selectedMerchant: merchant,
      }));

      history.push({
        pathname: `/transfer/${merchant?.id}`,
        state: { page: pageNumber },
        ...(selectedBonus?.id ? { search: `?b=${selectedBonus.id}` } : {}),
      });
    }
  };

  const isMerchantListFilteredByBonus =
    isMerchantsFilteredByBonus || filteredMerchantsByBonus?.length;

  useEffect(() => {
    setData(merchants);
  }, [merchants]); // eslint-disable-line react-hooks/exhaustive-deps

  const changePage = ({ selected }) => {
    setPageNumber(selected + 1);
  };

  useEffect(() => {
    setWalletData((prevState) => ({
      ...prevState,
      selectedMerchant: {},
    }));
  }, [setWalletData]);

  useEffect(
    () => () => {
      setWalletData((prevState) => ({
        ...prevState,
        transactionData: { amount: null },
        filteredMerchantsByBonus: null,
        selectedBonus: null,
        isMerchantsFilteredByBonus: null,
        transactionSubmitError: false,
      }));
    },
    [setWalletData],
  );

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: loadingMerchants,
    hasNextPage: true,
    onLoadMore: getMoreData,
    rootMargin: '-100px -100px -70px -100px',
    delayInMs: REQUEST_DELAY_IN_MS,
    disabled: !isMobileLayout,
  });

  const transferBonuses = userData?.bonuses?.filter(
    ({ transaction_type }) => transaction_type === 'transfer-in',
  );

  const selectedMerchantBonuses = filterMerchantHasBonuses(
    transferBonuses,
    selectedMerchant?.id,
  );

  useEffect(() => {
    if (initialSelectedMerchant) {
      const initialMerchant = merchants.find(
        ({ id }) => id === initialSelectedMerchant,
      );
      setWalletData((prevState) => ({
        ...prevState,
        selectedMerchant: initialMerchant,
      }));
    }
  }, [initialSelectedMerchant, merchants, setWalletData]);

  useEffect(() => {
    if (data.length && merchantSlug) {
      const selectedRedirectionMerchant = data.find(
        (merchant) => merchant.slug === merchantSlug,
      );
      if (selectedRedirectionMerchant) {
        handleClick(selectedRedirectionMerchant);
      }
    }
  }, [merchantSlug, data]);

  return (
    <MainTemplate
      containerProps={{ ref: rootRef, gradientLight: true }}
      headerProps={{
        showAvailableBalance: true,
        title: i18n.t('selectMerchant.title'),
        amountHighlighted: true,
        returnAction: transactionSubmitError
          ? () =>
              setWalletData((prevState) => ({
                ...prevState,
                transactionSubmitError: false,
              }))
          : showDetails
          ? () => setShowDetails(false)
          : history.goBack,
      }}
    >
      <ContentWrapper isOpen={!isMobileLayout}>
        {showDetails ? (
          <TransactionPreview
            setShowDetails={setShowDetails}
            type="transfer"
            redirectTo="/transfer"
          />
        ) : (
          <Section isOpen={!isMobileLayout}>
            {!loadingMerchants ? (
              <>
                <ReverseWrapper>
                  <TwTitle
                    size={isMobileLayout ? 'm_lg' : 'd_lg'}
                    font="arboriaBold"
                    classList={`text-center ${isMobileLayout && 'max-w-60'}`}
                  >
                    <Trans
                      i18nKey={i18n.t(
                        filteredMerchantsByBonus?.length
                          ? 'selectMerchant.merchantsWithBonuses'
                          : 'selectMerchant.selectMerchant',
                      )}
                      i18n={i18n}
                      components={[<span className="text-grass-800 inline" />]}
                    />
                  </TwTitle>
                </ReverseWrapper>
                <MerchantsWrapper>
                  <Merchant
                    id="transfer-in-merchant-list"
                    listLength={data?.length}
                  >
                    {data.map((el, idx) => {
                      const multiplierPoints =
                        el?.transferin_multiplier_points > 1 &&
                        !userData?.points_blocked
                          ? el.transferin_multiplier_points
                          : 0;
                      const { id, slug, name, image_url } = el;
                      return (
                        <LogoWrapper
                          selected={selectedMerchant?.slug === slug}
                          ref={sentryRef}
                          onClick={() => handleClick(el)}
                          index={idx}
                          key={`${el.slug}-${idx + 1}`}
                          id={slug}
                        >
                          <MerchantIcon
                            imageUrl={image_url}
                            width={ICON_SIZE}
                            opacity={
                              !isMobileLayout &&
                              selectedMerchant?.slug !== slug &&
                              LOGO_OPACITY
                            }
                          />
                          <p
                            className={`max-w-20 ${
                              isMobileLayout
                                ? 'text-aches-500 text-left'
                                : 'text-white text-center'
                            }`}
                          >
                            {name}
                          </p>

                          <TwBonusIndicator multiplier={multiplierPoints}>
                            {!isMerchantListFilteredByBonus &&
                              checkIfMerchantHasBonuses(
                                userData?.bonuses,
                                id,
                              ) &&
                              !isFetchingBonuses && (
                                <BonusIcon size={ICON_SIZE} />
                              )}
                          </TwBonusIndicator>
                          <ArrowButton>
                            <Arrow />
                          </ArrowButton>
                        </LogoWrapper>
                      );
                    })}
                    {loadingMoreData && (
                      <div className="w-full py-2 flex items-center justify-center">
                        <Loading />
                      </div>
                    )}
                  </Merchant>
                </MerchantsWrapper>
                <HideInMobile>
                  {selectedBonus ? (
                    <ChangeBonusButton
                      type="transfer-in"
                      customAction={
                        isMerchantListFilteredByBonus
                          ? () => history.push('/bonuses-and-promotions')
                          : null
                      }
                    />
                  ) : (
                    pageCount <= FIRST_PAGE && <Spacer />
                  )}
                </HideInMobile>
                {pageCount > FIRST_PAGE &&
                  !loadingMerchants &&
                  !isMobileLayout && (
                    <PaginationWrapper>
                      <ReactPaginate
                        nextLabel={<IoIosArrowForward />}
                        previousLabel={<IoIosArrowBack />}
                        disableInitialCallback
                        forcePage={pageNumber - 1}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={NUM_OF_PAGES_DEFAULT}
                        activeLinkClassName="current-page"
                        disabledClassName="button-disabled"
                        pageLinkClassName="page-link"
                        previousLinkClassName="prev-button"
                        nextLinkClassName="next-button"
                      />
                    </PaginationWrapper>
                  )}
              </>
            ) : (
              <div className="h-full flex items-center">
                <Loading marginTop="12%" />
              </div>
            )}
          </Section>
        )}
        {(selectedMerchant?.slug ||
          (isMerchantListFilteredByBonus && !selectedBonus)) &&
        !isMobileLayout ? (
          <>
            <SectionDivider />
            {selectedMerchantBonuses?.length && !selectedBonus ? (
              <BonusSelector
                isVisible
                selectedMerchant={selectedMerchant}
                data={selectedMerchantBonuses}
              />
            ) : (
              <Section>
                <DesktopTransferForm
                  setShowDetails={setShowDetails}
                  showDetails={showDetails}
                />
              </Section>
            )}
          </>
        ) : (
          <>
            <SectionDivider />
            <GetStartWithPoints />
          </>
        )}
      </ContentWrapper>
    </MainTemplate>
  );
}

export default SelectMerchant;
