import React from 'react';
import PropTypes from 'prop-types';

function TwListHeader({ items, children }) {
  return (
    <div className="w-full">
      {items && items.length > 0 && (
        <div className="flex items-center text-[20px] text-ashes-500 font-arboria-bold bg-shadow-800 h-[45px] mb-4 ">
          {items.map((item) => (
            <span
              key={item.name}
              className={`text-left ${item?.margin} ${item?.size}`}
            >
              {item.name}
            </span>
          ))}
        </div>
      )}
      {children}
    </div>
  );
}

TwListHeader.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      size: PropTypes.string,
      margin: PropTypes.string,
    }),
  ).isRequired,
  children: PropTypes.node,
};

export default TwListHeader;
