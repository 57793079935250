import React from 'react';
import PropTypes from 'prop-types';

import { ReturnIcon, Container, AbsoluteContainer } from './styles';

const ICON_SIZE = 13;

function ReturnButton({
  handleClick,
  color,
  title,
  left,
  top,
  isRollback,
  position,
}) {
  return (
    <AbsoluteContainer
      onClick={handleClick}
      left={left}
      top={top}
      id="return-button"
      position={position}
    >
      <Container type="button" color={color}>
        <ReturnIcon color={color} height={ICON_SIZE} />
      </Container>
      {isRollback && <span>{title}</span>}
    </AbsoluteContainer>
  );
}

ReturnButton.propTypes = {
  title: PropTypes.string,
  left: PropTypes.string,
  top: PropTypes.string,
  handleClick: PropTypes.func,
  color: PropTypes.string,
  position: PropTypes.string,
}.isRequired;

export default ReturnButton;
