import React, { useEffect, useState } from 'react';

import ReactPaginate from 'react-paginate';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import ErrorMessage from '@/components/molecules/ErrorMessage';
import BonusHistoryFilter from '@/components/organisms/BonusHistoryFilter';
import Loading from '@/components/atoms/Loading';
import SectionDivider from '@/components/atoms/SectionDivider/styles';
import MainTemplate from '@/components/templates/MainTemplate';
import i18n from '@/translate/i18n';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import { ReactComponent as FilterIcon } from '@/assets/filterIcon.svg';
import { Paragraph } from '@/components/atoms/Typography/styles';

import {
  ContentWrapper,
  FilterButton,
  ListWrapper,
  PaginationWrapper,
  Section,
} from './styles';
import { getBonusHistory } from '@/services/api';
import BonusHistoryResume from '@/components/atoms/BonusHistoryResume';
import Modal from '@/components/molecules/Modal';
import ModalContent from '@/components/atoms/ModalContent';
import TwTitle from '@/components/atoms/TwTitle';
import TwListHeader from '@/components/atoms/TwListHeader';

const initialFilter = {
  order_by: 'created_at',
  order: 'desc',
  page: 1,
  status: 'all',
  merchant_id: 'all',
};

const ITEMS_PER_PAGE = 10;
const ITEMS_PER_PAGE_MOBILE = 10;
const NUM_OF_PAGES_DEFAULT = 1;

function BonusesHistory() {
  const { isMobileLayout } = useDeviceWidth();
  const [errorMessage, setErrorMessage] = useState('');
  const [currentFilters, setCurrentFilters] = useState(initialFilter);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [bonusHistory, setBonusHistory] = useState([]);
  const [selectedBonus, setSelectedBonus] = useState(null);
  const [pageCount, setPageCount] = useState(NUM_OF_PAGES_DEFAULT);
  const [showModal, setShowModal] = useState(false);

  const defaultFilters = {
    order_by: 'created_at',
    order: 'desc',
    per_page: isMobileLayout ? ITEMS_PER_PAGE_MOBILE : ITEMS_PER_PAGE,
  };
  const merchantList = JSON.parse(localStorage.getItem('merchants'));

  const getUserBonus = async (newFilters = {}, signal) => {
    try {
      setIsLoading(true);
      const params = { ...defaultFilters, ...newFilters };
      if (params.merchant_id === 'all') {
        delete params.merchant_id;
      }
      if (params.status === 'all') {
        delete params.status;
      }
      const res = await getBonusHistory(params, signal);
      setBonusHistory(res.data);
      setPageCount(Math.ceil(res.meta.total / ITEMS_PER_PAGE));
    } catch (err) {
      const message = err?.response?.data?.message;
      setErrorMessage(
        i18n.t([
          `error.transactionHistory.${message}`,
          'error.transactionHistory.unspecific',
        ]),
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();

    getUserBonus({ ...currentFilters, page: pageNumber }, controller.signal);

    return () => controller.abort();
  }, [pageNumber]);

  const changePage = ({ selected }) => {
    setPageNumber(selected + 1);
  };

  const headerList = [
    { name: i18n.t('bonus.bonus'), size: 'w-1/2', margin: 'ml-3' },
    { name: i18n.t('bonus.received'), size: 'w-1/5' },
    { name: i18n.t('bonus.statusTitle'), size: 'w-1/5', margin: 'ml-5' },
  ];

  const isFiltered =
    JSON.stringify(initialFilter) !== JSON.stringify(currentFilters);

  return (
    <MainTemplate
      headerProps={{
        returnTo: '/wallet',
        title: i18n.t('bonusesHistory.title'),
        returnAction: showFilter
          ? () => {
              setShowFilter(false);
            }
          : null,
        customButton: !showFilter && (
          <FilterButton>
            <FilterIcon
              onClick={() => {
                setShowFilter((state) => !state);
              }}
            />
          </FilterButton>
        ),
      }}
    >
      <ContentWrapper>
        <Section hideInMobile={showFilter}>
          <TwTitle
            size={isMobileLayout ? 'm_lg' : 'd_lg'}
            font="arboriaBold"
            classList="text-center"
          >
            {i18n.t('bonusesHistory.title')}
          </TwTitle>
          {!isLoading && !errorMessage.length ? (
            <>
              <ListWrapper
                id="transactions-list"
                justifyContent={!bonusHistory.length && 'center'}
              >
                {errorMessage ? (
                  <ErrorMessage content={errorMessage} />
                ) : (
                  <TwListHeader items={headerList}>
                    {bonusHistory.map((bonus) => (
                      <BonusHistoryResume
                        key={bonus.id}
                        data={bonus}
                        setModalContent={() => {
                          setSelectedBonus(bonus);
                          setShowModal(true);
                        }}
                      />
                    ))}
                  </TwListHeader>
                )}
                {!isLoading && !errorMessage.length && !bonusHistory.length && (
                  <Paragraph textAlign="center" style={{ margin: '0 auto' }}>
                    {i18n.t(
                      isFiltered
                        ? 'transactionHistory.emptyData'
                        : 'bonus.noData',
                    )}
                  </Paragraph>
                )}
              </ListWrapper>
              <span />
            </>
          ) : (
            <Loading />
          )}
          {Boolean(pageCount) && !isLoading && (
            <PaginationWrapper>
              <ReactPaginate
                breakLabel=""
                nextLabel={<IoIosArrowForward />}
                previousLabel={<IoIosArrowBack />}
                disableInitialCallback
                forcePage={pageNumber - 1}
                pageCount={pageCount}
                onPageChange={changePage}
                marginPagesDisplayed={1}
                pageRangeDisplayed={NUM_OF_PAGES_DEFAULT}
                activeLinkClassName="current-page"
                disabledClassName="button-disabled"
                pageLinkClassName="page-link"
                previousLinkClassName="prev-button"
                nextLinkClassName="next-button"
              />
            </PaginationWrapper>
          )}
        </Section>
        <SectionDivider height="75%" />
        {(showFilter || !isMobileLayout) && (
          <Section>
            <TwTitle
              size={isMobileLayout ? 'm_lg' : 'd_lg'}
              font="arboriaBold"
              classList="text-center"
            >
              {i18n.t('transactionHistory.filters')}
            </TwTitle>
            <BonusHistoryFilter
              currentFilters={currentFilters}
              merchantList={merchantList}
              handleSubmit={getUserBonus}
              initialFilter={initialFilter}
              setCurrentFilters={setCurrentFilters}
              setShowFilter={setShowFilter}
              showFilter={showFilter}
            />
            <span />
          </Section>
        )}
      </ContentWrapper>
      <Modal
        showModal={showModal}
        setShowModal={() => {
          setShowModal(false);
          setTimeout(() => {
            setSelectedBonus(null);
          }, 200);
        }}
        height="100px"
      >
        <ModalContent bonus={selectedBonus} />
      </Modal>
    </MainTemplate>
  );
}

export default BonusesHistory;
