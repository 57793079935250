import React from 'react';
import PropTypes from 'prop-types';

import i18n from '@/translate/i18n';
import { Title } from '@/components/atoms/Typography/styles';
import { formatCurrency, formatShortDate } from '@/helpers/stringFormat';

import {
  AmountContainer,
  ContentWrapper,
  GiftIcon,
  Text,
  TextWrapper,
  Value,
} from '@/components/molecules/TransactionResume/styles';

function TransferResume({ data }) {
  const updatedAt = data?.updated_at && formatShortDate(data?.updated_at, true);
  const { bonus, bonus_amount } = data.bonus || {};

  const isTransferIn = data?.type === 'transfer-in';
  const isTransferOut = data?.type === 'transfer-out';

  return (
    <ContentWrapper>
      <TextWrapper>
        <Title position="left" size="xSmall" textColor="light">
          {i18n.t(`transactionResume.${data?.type}`)}{' '}
          {data?.details?.merchant_name || data?.details?.merchant_slug || ''}
          {bonus && <GiftIcon />}
        </Title>
        <Text className="!text-left">{updatedAt}</Text>
      </TextWrapper>
      <AmountContainer>
        <Value
          status={data.type}
          type={isTransferOut ? 'positive' : 'negative'}
        >
          {(isTransferIn && '- ') || (isTransferOut && '+ ') || ''}
          {formatCurrency(
            data.customer_amount + (bonus_amount || 0),
            data.customer_currency_iso,
          )}
        </Value>
      </AmountContainer>
    </ContentWrapper>
  );
}

TransferResume.propTypes = {
  data: PropTypes.shape({}),
}.isRequired;

export default TransferResume;
