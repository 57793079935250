import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import BonusListItem from '@/components/molecules/BonusListItem';
import BonusesAndPromotionsIcon from '@/components/molecules/BonusesAndPromotionsIcon';
import i18n from '@/translate/i18n';
import useAllMerchants from '@/hooks/useAllMerchants';
import { useUserData } from '@/context/userContext';

import { ListWrapper, Wrapper } from './styles';
import { LinkTextButton } from '@/components/atoms/Buttons/TextButton/styles';

function BonusList({ selectedBonus, setSelectedBonus, setShowModal }) {
  const {
    userData: { bonuses },
  } = useUserData();

  const { getAllMerchants, isFetching } = useAllMerchants();

  useEffect(() => {
    const controller = new AbortController();
    const merchantList = JSON.parse(localStorage.getItem('merchants'));

    if (!merchantList && !isFetching) {
      getAllMerchants();
    }

    return () => controller.abort();
  }, []);

  return (
    <Wrapper>
      <BonusesAndPromotionsIcon type="bonuses" />
      <ListWrapper id="bonus-list">
        {bonuses?.length ? (
          bonuses?.map((bonus) => (
            <BonusListItem
              key={bonus.id}
              bonus={bonus}
              selectedBonus={selectedBonus}
              handleClick={setSelectedBonus}
              showTransactionType
              setShowModal={setShowModal}
            />
          ))
        ) : (
          <p>{i18n.t('bonusesAndPromotions.noBonusFound')}</p>
        )}
        <LinkTextButton
          className="pt-5 !text-lg"
          to="/bonuses-and-promotions/history"
        >
          {i18n.t('bonus.showHistory')}
        </LinkTextButton>
      </ListWrapper>
      <span />
    </Wrapper>
  );
}

BonusList.propTypes = {
  selectedBonus: PropTypes.shape,
  setSelectedBonus: PropTypes.func,
}.isRequired;

export default BonusList;
