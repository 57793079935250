import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import ConversionResume from '@/components/atoms/ConversionResume';
import DepositResume from '@/components/atoms/DepositResume';
import HistoryIcon from '@/components/atoms/HistoryIcon';
import TransferResume from '@/components/atoms/TransferResume';
import WithdrawalResume from '@/components/atoms/WithdrawalResume';

import { ResumeWrapper } from './styles';

function TransactionResume({ data }) {
  const history = useHistory();
  const { id, status, type } = data;
  const resumeTypes = {
    conversion: <ConversionResume data={data} />,
    deposit: <DepositResume data={data} />,
    withdrawal: <WithdrawalResume data={data} />,
    'transfer-in': <TransferResume data={data} />,
    'transfer-out': <TransferResume data={data} />,
    default: 'Unknown transaction type',
  };

  const getResumeType = (transactionType) =>
    resumeTypes[transactionType] || resumeTypes.default;

  return (
    <ResumeWrapper onClick={() => history.push(`/transactions/${id}`)}>
      <HistoryIcon type={type} status={status} width="25px" />
      {getResumeType(data.type)}
    </ResumeWrapper>
  );
}

TransactionResume.propTypes = {
  data: PropTypes.shape({}),
}.isRequired;

export default TransactionResume;
