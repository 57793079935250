import React, { useState } from 'react';
import PropTypes from 'prop-types';

import DateRangePicker from '@/components/molecules/DateRangePicker';
import Select from '@/components/atoms/Select';
import i18n from '@/translate/i18n';
import { Paragraph } from '@/components/atoms/Typography/styles';
import { Button } from '@/components/atoms/Buttons/Button/styles';
import { TextButton } from '@/components/atoms/Buttons/TextButton/styles';

import {
  ButtonsWrapper,
  Container,
  Content,
  DatesWrapper,
  InputWrapper,
} from './styles';

const statusOptions = [
  { label: i18n.t('bonus.status.all'), value: 'all' },
  { label: i18n.t('bonus.status.available'), value: 'available' },
  { label: i18n.t('bonus.status.used'), value: 'used' },
  { label: i18n.t('bonus.status.out'), value: 'out' },
  { label: i18n.t('bonus.status.expired'), value: 'expired' },
];

function BonusHistoryFilter({
  currentFilters,
  merchantList,
  handleSubmit,
  initialFilter,
  setCurrentFilters,
  setShowFilter,
  showFilter,
}) {
  const [newFilters, setNewFilters] = useState(currentFilters);
  const list = merchantList.map((merchant) => ({
    value: merchant.id,
    label: merchant.name,
  }));
  const merchantOptions = [{ label: 'Todos', value: 'all' }, ...list];

  const resetFilter = () => {
    setCurrentFilters(initialFilter);
    setNewFilters(initialFilter);
  };
  const isDirty = JSON.stringify(currentFilters) !== JSON.stringify(newFilters);
  const isFiltered =
    JSON.stringify(newFilters) !== JSON.stringify(initialFilter);
  const filtersChanged =
    JSON.stringify(currentFilters) !== JSON.stringify(initialFilter);
  return (
    <>
      <Container showFilter={showFilter} id="bonus-filter">
        <Content>
          <InputWrapper>
            <DatesWrapper>
              <Paragraph family="secondary" color="title">
                {i18n.t('transactionHistory.dateRange')}
              </Paragraph>
              <DateRangePicker setState={setNewFilters} filters={newFilters} />
            </DatesWrapper>
            <Select
              options={merchantOptions}
              id="merchant_id"
              value={newFilters.merchant_id}
              onChange={({ target: t }) => {
                setNewFilters({ ...newFilters, merchant_id: t.value });
              }}
              width="120px"
              color="light"
              variant="rounded"
            />
            <Paragraph family="secondary" color="title">
              {i18n.t('bonus.filters.merchant')}
            </Paragraph>
            <Select
              options={statusOptions}
              id="status"
              value={newFilters.status}
              onChange={({ target: t }) => {
                setNewFilters({ ...newFilters, status: t.value });
              }}
              width="120px"
              color="light"
              variant="rounded"
            />
            <Paragraph family="secondary" color="title">
              {i18n.t('bonus.filters.status')}
            </Paragraph>
          </InputWrapper>
          {isFiltered && (
            <ButtonsWrapper>
              <Button
                type="button"
                disabled={!isDirty}
                onClick={() => {
                  setShowFilter(false);
                  setCurrentFilters(newFilters);
                  handleSubmit(newFilters, null);
                }}
              >
                {i18n.t('transactionHistory.apply')}
              </Button>
              <TextButton
                type="button"
                onClick={() => {
                  resetFilter();
                  setShowFilter(false);
                  if (filtersChanged) {
                    handleSubmit(initialFilter, null);
                  }
                }}
              >
                {i18n.t('transactionHistory.clear')}
              </TextButton>
            </ButtonsWrapper>
          )}
        </Content>
      </Container>
    </>
  );
}

BonusHistoryFilter.propTypes = {
  setCurrentFilters: PropTypes.func,
  setShowFilter: PropTypes.func,
  filterParams: PropTypes.shape({
    type: PropTypes.string,
    status: PropTypes.string,
    startAt: PropTypes.string,
    endDate: PropTypes.string,
  }),
}.isRequired;

export default BonusHistoryFilter;
