import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Loading from '@/components/atoms/Loading';
import MainTemplate from '@/components/templates/MainTemplate';
import PaymentCarousel from '@/components/molecules/PaymentCarousel';
import SectionDivider from '@/components/atoms/SectionDivider/styles';
import TransactionError from '@/components/molecules/TransactionError';
import WithdrawalForm from '@/components/organisms/WithdrawalForm';
import i18n from '@/translate/i18n';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import useTransactionLimits from '@/hooks/useTransactionLimits';
import useConversion from '@/hooks/useConversion';
import useMethods from '@/hooks/useMethods';
import { Section } from '@/components/pages/Deposit/styles';
import { DEFAULT_METHOD_SLUG, registrationStatus } from '@/helpers/constants';
import { useUserData } from '@/context/userContext';
import { useWalletData } from '@/context/walletContext';

function Withdrawal() {
  const history = useHistory();

  const {
    setWalletData,
    walletData: { selectedWallet, transactionData },
  } = useWalletData();
  const { userData } = useUserData();
  const { exchangeData, exchangeError } = useConversion(selectedWallet, 'BRL');
  const { loadingMethods, methodsError } = useMethods();
  const { isMobileLayout } = useDeviceWidth();

  const { limitsError, transactionLimits } = useTransactionLimits(
    'withdrawal',
    selectedWallet,
    transactionData?.method?.slug ?? DEFAULT_METHOD_SLUG,
  );

  useEffect(() => {
    if (userData.status <= registrationStatus.validateRegistration) {
      history.push('/wallet');
    }
  }, [history, userData]);

  useEffect(
    () => () => {
      setWalletData((prevState) => ({
        ...prevState,
        transactionData: { amount: null },
        transactionSubmitError: false,
      }));
    },
    [setWalletData],
  );

  const conditionsToRenderPaymentInfo = [
    loadingMethods,
    limitsError,
    exchangeError,
    methodsError,
  ];

  const shouldRenderPaymentInfo = conditionsToRenderPaymentInfo.every(
    (item) => !item,
  );

  return (
    <MainTemplate
      headerProps={{
        title: i18n.t('withdrawal.title'),
        returnTo: '/wallet',
        showAvailableBalance: true,
      }}
    >
      {shouldRenderPaymentInfo ? (
        <Section isOpen>
          {!isMobileLayout && (
            <PaymentCarousel
              disableBonusSelection
              customMessage={i18n.t('withdrawal.pixKeyRequired')}
              description={i18n.t('paymentSelector.title')}
            />
          )}

          <SectionDivider />
          <WithdrawalForm
            transactionLimits={transactionLimits}
            exchangeData={exchangeData}
          />
        </Section>
      ) : methodsError || limitsError || exchangeError ? (
        <TransactionError
          content={i18n.t('error.withdrawal.limitsOrMethodError')}
          height="80%"
        />
      ) : (
        <Loading />
      )}
    </MainTemplate>
  );
}

export default Withdrawal;
