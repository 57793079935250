import styled from 'styled-components';

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  width: 100%;

  a {
    margin-top: 1rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    & > div {
      display: flex;
      justify-content: flex-end;
      max-width: 456px;
      width: 100%;
    }
  }
`;
