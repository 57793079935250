import styled from 'styled-components';

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 5vh;
  height: 100%;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    justify-content: space-between;
  }
`;

export const ListWrapper = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.info.primary};
  display: flex;
  flex-direction: column;
  font-size: ${({ theme }) => theme.font.size.small};
  max-height: 70vh;
  overflow: auto;
  width: 85%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    max-height: 50vh;
    padding: 0 1rem 2rem 2rem;
    width: 100%;
  }
`;
