import styled from 'styled-components';

export const Wrapper = styled.div`
  align-items: center;
  animation: goRightDefault 0.8s;
  color: ${({ theme }) => theme.colors.info.primary};
  display: flex;
  flex-direction: column;
  gap: 4rem;
  margin-bottom: ${({ customMargin = '5vh' }) => customMargin};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    justify-content: space-between;
    margin-bottom: ${({ customMargin = 0 }) => customMargin};
    max-height: 550px;
  }
`;

export const ContentWrapper = styled.div`
  align-items: center;
  align-self: center;
  animation: goRightDefault 0.8s;
  color: ${({ theme }) => theme.colors.info.primary};
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
