import React from 'react';
import PropTypes from 'prop-types';

import i18n from '@/translate/i18n';
import { formatCurrency, formatShortDate } from '@/helpers/stringFormat';
import { useWalletData } from '@/context/walletContext';
import { Title } from '@/components/atoms/Typography/styles';

import {
  ArrowRight,
  ContentWrapper,
  Text,
  TextWrapper,
  Value,
} from '@/components/molecules/TransactionResume/styles';

function ConversionResume({ data }) {
  const {
    walletData: { selectedWallet },
  } = useWalletData();

  const updatedAt = data?.updated_at && formatShortDate(data?.updated_at, true);

  const conversionAmount =
    data?.details?.from_currency === selectedWallet
      ? data.details.from_amount
      : data.details.to_amount;

  const conversionCurrency =
    data?.details?.from_currency === selectedWallet
      ? data.details.from_currency
      : data.details.to_currency;

  const addedFundsConversion = data?.details?.to_currency === selectedWallet;
  const removedFundsConversion =
    data?.details?.from_currency === selectedWallet;
  return (
    <ContentWrapper>
      <TextWrapper>
        <Title position="left" size="xSmall" textColor="light">
          {i18n.t(`transactionHistory.${data?.type}`)}{' '}
          {data?.details?.from_currency} <ArrowRight />{' '}
          {data?.details?.to_currency}
        </Title>
        <Text>{updatedAt}</Text>
      </TextWrapper>
      <Value>
        {addedFundsConversion ? '+ ' : removedFundsConversion ? '- ' : ' '}
        {formatCurrency(conversionAmount, conversionCurrency)}
      </Value>
    </ContentWrapper>
  );
}

ConversionResume.propTypes = {
  data: PropTypes.shape({}),
}.isRequired;

export default ConversionResume;
