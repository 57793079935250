import styled from 'styled-components';
import { BsArrowRight } from 'react-icons/bs';

import { hexToRGBA } from '@/styles/colors';
import { ReactComponent as Gift } from '@/assets/giftIcon.svg';

export const ResumeWrapper = styled.div`
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  font-size: ${({ theme }) => theme.font.size.xSmall};
  justify-content: space-between;
  max-width: 768px;
  min-height: 50px;
  padding: 15px 5px;
  position: relative;
  width: 100%;

  &:not(:last-child) {
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.info.gray}`};
  }

  &:hover {
    background: radial-gradient(
      circle,
      ${({ theme }) => hexToRGBA(theme.colors.info.gray, 0.05)} 0%,
      ${({ theme }) => hexToRGBA(theme.colors.info.gray, 0.5)} 0%,
      ${({ theme }) => hexToRGBA(theme.colors.info.gray, 0.05)} 100%
    );
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    min-height: 52px;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex: 0.6;
  flex-direction: column;
  margin-left: 20px;

  span {
    font-size: ${({ theme }) => theme.font.size.xSmall};
    text-transform: uppercase;
  }

  h1 strong {
    color: ${({ theme }) => theme.colors.pending} !important;
    font-size: ${({ theme }) => theme.font.size.xSmall};
    text-transform: uppercase;
  }

  h1 {
    display: flex;
    gap: 5px;
  }
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.info.primary};
  font-size: ${({ theme }) => theme.font.size.xxSmall};
  font-weight: 300;
  min-width: 150px;
`;

export const Value = styled.p`
  color: ${({ theme, status = 'completed' }) =>
    status === 'cancelled' ? theme.colors.info.title : theme.colors.info.light};
  font: ${({ theme }) =>
    `${theme.font.size.small} ${theme.font.family.secondary}`};
`;

export const ArrowRight = styled(BsArrowRight)`
  margin: 0 3px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

export const AmountContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

export const BonusExpression = styled.p`
  color: ${({ theme }) => theme.colors.info.title};
  font-size: ${({ theme }) => theme.font.size.xSmall};
  font-weight: 300;

  > span {
    color: ${({ theme, type }) =>
      theme.colors.info[type] || theme.colors.info.fixed};
    font-family: ${({ theme }) => theme.font.family.secondary};
  }
`;

export const Points = styled(BonusExpression)`
  > span {
    color: ${({ theme }) => theme.colors.info.points};
  }
`;

export const GiftIcon = styled(Gift)`
  width: 12px;
`;
