import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import i18n from '@/translate/i18n';
import MainTemplate from '@/components/templates/MainTemplate';
import { ContainerContent } from '../Cashback/styles';
import { Title } from '@/components/molecules/WalletHeader/style';
import CarouselMech from '@/components/organisms/CarouselMech';
import { getListMerchantsCashback } from '@/services/api';
import BackButton from '@/components/atoms/BackButton';

import SkeletonCarouselMech from '@/components/organisms/CarouselMech/SkeletonCarouselMech';

import useDeviceWidth from '@/hooks/useDeviceWidth';
import CurrentBalance from '@/components/molecules/CurrentBalance';
import {
  ActiveMechButton,
  BackButtonContainer,
  BackButtonContent,
  CenterContainer,
} from './styles';

const FIRST_ELEMENT = 0;
const ZERO = 0;

function CashbackMerchantsActives() {
  const [merchantsAvaible, setMerchantsAvaible] = useState([]);
  const [selectedMerchant, setSelectedMerchant] = useState({});
  const history = useHistory();

  const [error, setError] = useState('');
  const { isMobileLayout } = useDeviceWidth();

  const handleSeeDetails = () => {
    history.push(`/cashback/merchants/${selectedMerchant.id}`);
  };

  const getMerchants = async () => {
    try {
      const res = await getListMerchantsCashback();
      const data = res.data.filter(
        (element) =>
          element.usernames.length > ZERO &&
          element.usernames[ZERO].cashback_active_by_customer,
      );
      setMerchantsAvaible(data);
      setSelectedMerchant(data[FIRST_ELEMENT]);
    } catch {
      setError(i18n.t('cashback.errorLoadMerchants'));
    }
  };

  const isLoading = useMemo(() => !merchantsAvaible.length, [merchantsAvaible]);

  useEffect(() => {
    getMerchants();
  }, []);

  return (
    <MainTemplate
      containerProps={{ gradient: true }}
      headerProps={{ mobileFullVersion: false }}
      hideHeader={isMobileLayout}
    >
      <BackButtonContainer>
        {!isMobileLayout && (
          <BackButtonContent>
            <BackButton to="/cashback" text="Voltar" />
          </BackButtonContent>
        )}
      </BackButtonContainer>
      <ContainerContent>
        {isMobileLayout && (
          <header className="w-full flex items-center mt-10 md:mt-5 px-5 relative">
            <div className="z-10">
              <BackButton to="/cashback" />
            </div>
            <div className="w-full absolute md:relative top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <Title fontSize={isMobileLayout ? '18px' : '32px'}>
                {i18n.t('cashback.title')}
              </Title>
            </div>
          </header>
        )}
        {isMobileLayout && <CurrentBalance />}
        <p className="font-bold text-xl md:text-2xl text-white w-1/2 md:w-auto">
          {i18n.t('cashback.chooseDetails')}
        </p>
        <CenterContainer>
          {isLoading ? (
            <SkeletonCarouselMech />
          ) : error ? (
            <p>{error}</p>
          ) : (
            <CarouselMech
              data={merchantsAvaible}
              setSelectedMerchant={setSelectedMerchant}
              handleClickItem={handleSeeDetails}
            />
          )}
        </CenterContainer>
        <ActiveMechButton
          onClick={() => handleSeeDetails()}
          loading={isLoading}
          className="!font-extrabold md:mb-3"
        >
          {i18n.t('cashback.seeKeysActive')}
        </ActiveMechButton>
      </ContainerContent>
    </MainTemplate>
  );
}

export default CashbackMerchantsActives;
