import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    animation: goLeftDefault 1s;
    border: none;
    box-shadow: none;
    grid-column: 2;
    grid-row: 1;
    height: 45vh;
    justify-self: center;
    max-height: 420px;
    max-width: 392px;
    min-height: 380px;
    min-width: 300px;
    overflow: visible;
    padding-left: 10px;
    transition: none;
    width: 18vw;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 650px;
  width: 90%;

  label {
    margin-top: 20px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    align-items: center;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    padding: 1vh 0;

    label {
      margin-top: 1vh;
    }
  }
`;

export const CheckBoxWrapper = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  margin: 0 0 1rem 2rem;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    margin: 1rem 0 2rem 2rem;
    width: 100%;
  }
`;

export const DatesWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const InputWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column-reverse;
  gap: 1rem;
  width: 100%;
`;

export const ButtonsWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
`;
